import React,{ Component } from 'react';
import SideBar from './header'
import Accordions from './accordions'
import './App.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class App extends Component {
	constructor() {
	  super()
	  this.state = {
		items: [],
		currentItem: {text:'', key:''},
		name:'text',
	  }
	}
	handleInput = e => {
		const itemText = e.target.value;
		const currentItem = { text: itemText, key: Date.now() }
		this.setState({
			currentItem,
		  })
	//  console.log(currentItem)
	}
	addItem = e => {
		e.preventDefault()
	}

	handleSubmit(event) {
		event.preventDefault();
		console.log(this.state.currentItem)
	}

	deleteItem = key => {
		const filteredItems = this.state.items.filter(item => {
			return item.key !== key
		})
		this.setState({
			items: filteredItems,
		})
	}
	render() {
	  return (
		<div className="App">
		  <div className="d-flex" id="wrapper">
			 
			<div className="bg-light border-right" id="sidebar-wrapper">
				<SideBar/>
			</div>
			<div id="page-content-wrapper">
				<nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
					<div className="collapse navbar-collapse" id="navbarSupportedContent">
						<ul className="navbar-nav ml-auto mt-2 mt-lg-0">
							<li className="nav-item active">
								<a className="nav-link" href="#"> &nbsp;</a>
							</li>
						</ul>
					</div>
				</nav>
				<div className="container-fluid">
					<Accordions/>
				</div>
			</div>
			</div>
		</div>
	  )
	}
  }

export default App;



