import React, { Component } from 'react'
class SideBar extends Component {
  render() {
    return (
        <div>
            <div className="sidebar-heading">Deck Api Doc </div>
            <div className="list-group list-group-flush">
                <a href="#" className="list-group-item list-group-item-action bg-light">Introduction</a>
                <a href="#" className="list-group-item list-group-item-action bg-light">Api Credentional</a>
                <a href="#" className="list-group-item list-group-item-action bg-light">Documentations</a>
            </div>
        </div>
    )
  }
}
export default SideBar