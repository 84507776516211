import React,{Component} from 'react';
import axios from 'axios';
import _ from 'lodash';
import { Accordion, AccordionItem } from 'react-light-accordion';
import 'react-light-accordion/demo/css/index.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';


const override = css`
    display: block;
    margin: 0 auto;
	border-color: red;
	position:absolute;
	top:50%;
`;
 
class Accordions extends Component {
	constructor(props) {
		super(props);
		this.state = {loading:false,token:'',ports:[],
		data:[
			{	
				mainTitle:'All Ports and Sailing Dates, Method "GET"',
				title:'',
				description:'Get all sailing dates months and ports',
				params:[
					{
						parameter:'',
						value:'',
						description:'Required in header',
						parameterType:'', 
						dataType:'',
						displayName:'Api-Token',
						filedName:'token'
					},
				],
				response:'',
				url:'/api/v1/get-all-ports',
				type:'get'
			},
			{	
				mainTitle:'Search Result, Method "POST"',
				title:' ',
				description:'Listing packages from ncl and Deckpro.in.',
				sample:{
					"port_id": "Singapore",
					"month": "June, 2019"
				},
				params:[
					{
						parameter:'', 
						value:'',
						description:'Required in header',
						parameterType:'', 
						dataType:'String',
						displayName:'token',
						filedName:'token'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'String',
						displayName:'port_id',
						filedName:'port_id'
					},
					{
						parameter:'', 
						value:'',
						description:'Required, eg. June, 2018',
						parameterType:'', 
						dataType:'Month, Year (F, Y)',
						displayName:'month',
						filedName:'month'
					}
				],
				response:'',
				url:'/api/v1/search-result',
				type:'post',
			},
			{	
				mainTitle:'Package Details, Method "POST"',
				title:'Title2',
				description:' ',
				sample:{
						"package_id":173,
						"sailing_date" : "2019-06-19",
						"port_id" :"Singapore",
						"vessel_name":"Genting Dream",
						"s_id" : 14,
						"adult" : 2,
						"child":0,
						"infant":0,
					},
				params:[
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'String',
						displayName:'Api-Token',
						filedName:'token'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Integer',
						displayName:'package_id',
						filedName:'package_id'
					},
					{
						parameter:'', 
						value:'',
						description:'YYYY-MM-DD | Required',
						parameterType:'', 
						dataType:'YYYY-MM-DD',
						displayName:'sailing_date',
						filedName:'sailing_date'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'String',
						displayName:'port_id',
						filedName:'port_id'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'String',
						displayName:'vessel_name',
						filedName:'vessel_name'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Integer',
						displayName:'s_id',
						filedName:'s_id'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Interger',
						displayName:'adult',
						filedName:'adult'
					},
					{
						parameter:'', 
						value:'',
						description:'Default 0 if no child',
						parameterType:'', 
						dataType:'Integer',
						displayName:'child',
						filedName:'child'
					},
					{
						parameter:'', 
						value:'',
						description:'Default 0 if no infant',
						parameterType:'', 
						dataType:'Integer',
						displayName:'infant',
						filedName:'infant'
					},
				],
				response:'',
				url:'/api/v1/package-details',
				type:'POST'
			},
			{	
				mainTitle:'Ncl Cabin Availability, Method "POST"',
				title:'',
				description:'Get List of cabin number for NCL Cruise',
				sample:{
						"package_id": 13170169,
						"adult": 2,
						"child": 0,
						"infant": 0,
						"category_id":"H5",
						"variant_id":"H5"
					},
				params:[
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'String',
						displayName:'Api-Token',
						filedName:'token'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Integer',
						displayName:'package_id',
						filedName:'package_id'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'string/int',
						displayName:'category_id',
						filedName:'category_id'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'String/Int',
						displayName:'variant_id',
						filedName:'variant_id'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Interger',
						displayName:'adult',
						filedName:'adult'
					},
					{
						parameter:'', 
						value:'',
						description:'Default 0 if no child',
						parameterType:'', 
						dataType:'Integer',
						displayName:'child',
						filedName:'child'
					},
					{
						parameter:'', 
						value:'',
						description:'Default 0 if no infant',
						parameterType:'', 
						dataType:'Integer',
						displayName:'infant',
						filedName:'infant'
					},
				],
				response:'',
				url:'/api/v1/ncl-cabin-availability',
				type:'POST'
			},
			{	
				mainTitle:'Block Cabins, Method "POST"',
				title:'Title2',
				description:'',
				sample:{
						"sailing_date":"2019-06-19",
						"package_id" : 173,
						"s_id" : 14,
						"variant_id":"140",
						"ship_name": "Genting Dream",
						"vessel_name": "Genting Dream",
						"category_id":"52",
						"vessel_id":"74",
						"faretype":"normal",
						"promo_id":"",
						"adult" : 2,
						"child":0,
						"infant" : 0,
						"CabinNumber" : "16700",
						"port_id":"Singapore",
						"ShipCode" :"",
						"number_of_nights":3,
						"fareCode": "normal",
						"customer_details" : [
							{	
								"title" : "Mr",
								"first_name" : "dsadsa",
								"last_name"  : "testddsadsds",
								"passport_no" : "12345",
								"date_of_birth" : "1998-01-22",
								"date_of_expiry" : "2021-10-23",
								"date_of_issue" : "2002-01-23",
								"gender" :"male",
								"natianality" :"IN"
							},{
								"title" : "Mr",
								"first_name" : "dsds dsdsd",
								"last_name"  : "testasdsdsaddsdsdf 2",
								"passport_no" : "22",
								"date_of_birth" : "1998-01-22",
								"date_of_expiry" : "2021-01-23",
								"date_of_issue" : "2002-01-23",
								"gender": "male",
								"natianality": "IN"
							}
						]
					},
				params:[
					{
						parameter:'', 
						value:'',
						description:'Required in header.',
						parameterType:'', 
						dataType:'String',
						displayName:'Api-Token',
						filedName:'token'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Integer',
						displayName:'package_id',
						filedName:'package_id'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'YYYY-MM-DD',
						displayName:'sailing_date',
						filedName:'sailing_date'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Integer',
						displayName:'s_id',
						filedName:'s_id'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Integer / String',
						displayName:'variant_id',
						filedName:'variant_id'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'String',
						displayName:'vessel_name',
						filedName:'vessel_name'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'integer / String',
						displayName:'category_id',
						filedName:'category_id'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Integer / String',
						displayName:'vessel_id',
						filedName:'vessel_id'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'String',
						displayName:'faretype',
						filedName:'faretype'
					},
					{
						parameter:'', 
						value:'',
						description:'Optional',
						parameterType:'', 
						dataType:'Integer/String',
						displayName:'promo_id',
						filedName:'promo_id'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Integer',
						displayName:'adult',
						filedName:'adult'
					},
					{
						parameter:'', 
						value:'',
						description:'Default 0 if not child',
						parameterType:'', 
						dataType:'Integer',
						displayName:'child',
						filedName:'child'
					},
					{
						parameter:'', 
						value:'',
						description:'Default 0 if not infant',
						parameterType:'', 
						dataType:'Integer',
						displayName:'infant',
						filedName:'infant'
					},
					{
						parameter:'', 
						value:'',
						description:'Required for Dream Ncl',
						parameterType:'', 
						dataType:'',
						displayName:'CabinNumber',
						filedName:'CabinNumber'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'String',
						displayName:'port_id',
						filedName:'port_id'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Integer',
						displayName:'number_of_nights',
						filedName:'number_of_nights'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:"String",
						displayName:'fareCode',
						filedName:'fareCode'
					},
					{
						parameter:'', 
						value: [	{	
							"title" : "Mr",
							"first_name" : "dsadsa",
							"last_name"  : "testddsadsds",
							"passport_no" : "12345",
							"date_of_birth" : "1998-01-22",
							"date_of_expiry" : "2021-10-23",
							"date_of_issue" : "2002-01-23",
							"gender" :"male",
							"natianality" :"IN"
						},{
							"title" : "Mr",
							"first_name" : "dsds dsdsd",
							"last_name"  : "testasdsdsaddsdsdf 2",
							"passport_no" : "22",
							"date_of_birth" : "1998-01-22",
							"date_of_expiry" : "2021-01-23",
							"date_of_issue" : "2002-01-23",
							"gender": "male",
							"natianality": "IN"
						}] ,
						description:'',
						parameterType:'', 
						dataType:'',
						displayName:'customer_details',
						filedName:'customer_details',
						displayInput:true
					},
				],
				response:'',
				url:'/api/v1/block-cabins',
				type:'post'
			},
			{	
				mainTitle:'NCL Cabin Hold, Method "POST"',
				title:'Title2',
				description:'',
				sample:{
						"package_id": 15000758,
						"category_id":"B1",
						"variant_code":"B1",
						"cabin_number": "15868"
						
					},
				params:[
					{
						parameter:'', 
						value:'',
						description:'Required in header',
						parameterType:'', 
						dataType:'',
						displayName:'Api-Token',
						filedName:'token'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Integer',
						displayName:'package_id',
						filedName:'package_id'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Integer/String',
						displayName:'category_id',
						filedName:'category_id'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Integer/String',
						displayName:'variant_code',
						filedName:'variant_code'
					},
					{
						parameter:'', 
						value:'',
						description:'Required for NCL Cruise Line',
						parameterType:'', 
						dataType:'Integer',
						displayName:'cabin_number',
						filedName:'cabin_number'
					},
				],
				response:'',
				url:'/api/v1/hold-cabin',
				type:'post'
			},
			{	
				mainTitle:'Fare Descriptions, Method "POST"',
				title:'Title2',
				description:'',
				sample:{
						"package_id": 173,
						"category_id":"52",
						"variant_id":"140",
						"port_id":"Singapore",
						"cabin_number": "16126",
						"fareType" : "normal",
						"cabin_type_id":"2",
						"promo_id": "",
						"adult" : 2,
						"child" : 0,
						"infant" : 0,
						"s_id" : 14,
						"vessel_mame":"Genting Dream",
						"sailing_date":"2019-06-19"
						
					},
				params:[
					{
						parameter:'', 
						value:'',
						description:'Required in Header',
						parameterType:'', 
						dataType:'',
						displayName:'Api-Token',
						filedName:'token'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Integer',
						displayName:'package_id',
						filedName:'package_id'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Integer/String',
						displayName:'category_id',
						filedName:'category_id'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Integer/String',
						displayName:'variant_id',
						filedName:'variant_id'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'String',
						displayName:'port_id',
						filedName:'port_id'
					},
					{
						parameter:'', 
						value:'',
						description:'Required for Ncl cruise Line',
						parameterType:'', 
						dataType:'',
						displayName:'cabin_number',
						filedName:'cabin_number'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'String',
						displayName:'fareType',
						filedName:'fareType'
					},	
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Integer',
						displayName:'adult',
						filedName:'adult'
					},
					{
						parameter:'', 
						value:'',
						description:'Defalt 0 if not child',
						parameterType:'', 
						dataType:'Integer',
						displayName:'child',
						filedName:'child'
					},
					{
						parameter:'', 
						value:'',
						description:'Defalt 0 if not infant',
						parameterType:'', 
						dataType:'',
						displayName:'infant',
						filedName:'infant'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Integer',
						displayName:'s_id',
						filedName:'s_id'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'String',
						displayName:'vessel_name',
						filedName:'vessel_name'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'YYYY-MM-DD',
						displayName:'sailing_date',
						filedName:'sailing_date'
					},
				],
				response:'',
				url:'/api/v1/fare-details',
				type:'post'
			},
			{	
				mainTitle:'Get Promo Details, Method "POST"',
				title:'Title2',
				description:'',
				sample:{
						"package_id": 14300054,
						"category_id":"H4",
						"variant_id":"H4",
						"port_id":"SEATTLE",
						"cabin_number": "16126",
						"fareType" : "BESTFARE",
						"adult" : 1,
						"child" : 0,
						"infant" : 0,
						"s_id" : 14,
						"vessel_name":"Norwegian Joy",
						"sailing_date":"2019-07-20"
					},
				params:[
					{
						parameter:'', 
						value:'',
						description:'Required in header',
						parameterType:'', 
						dataType:'String',
						displayName:'Api-Token',
						filedName:'token'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Integer',
						displayName:'package_id',
						filedName:'package_id'
					},
					{
						parameter:'', 
						value:'',
						description:'Integer',
						parameterType:'', 
						dataType:'Integer',
						displayName:'category_id',
						filedName:'category_id'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Integer/String',
						displayName:'variant_id',
						filedName:'variant_id'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'String',
						displayName:'port_id',
						filedName:'port_id'
					},
					{
						parameter:'', 
						value:'',
						description:'Required for NCL cruise liner',
						parameterType:'', 
						dataType:'Integer',
						displayName:'cabin_number',
						filedName:'cabin_number'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'String',
						displayName:'fareType',
						filedName:'fareType'
					},	
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Required',
						displayName:'adult',
						filedName:'adult'
					},
					{
						parameter:'', 
						value:'',
						description:'Default 0 if no child',
						parameterType:'', 
						dataType:'Interg',
						displayName:'child',
						filedName:'child'
					},
					{
						parameter:'', 
						value:'',
						description:'Default 0 if no infant',
						parameterType:'', 
						dataType:'Integer',
						displayName:'infant',
						filedName:'infant'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Integer',
						displayName:'s_id',
						filedName:'s_id'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'String',
						displayName:'vessel_name',
						filedName:'vessel_name'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'YYYY-MM-DD',
						displayName:'sailing_date',
						filedName:'sailing_date'
					},
				],
				response:'',
				url:'/api/v1/get-promo-details',
				type:'post'
			},
			{	
				mainTitle:'"',
				title:'',
				description:'',
				sample:{},
				params:[
					{
						parameter:'', 
						value:'',
						description:'Required in header',
						parameterType:'', 
						dataType:'String',
						displayName:'Api-Token',
						filedName:'token'
					},
				],
				response:'',
				url:'/api/v1/get-all-blocks?paginate=10',
				type:'get'
			},
			{	
				mainTitle:'"',
				title:'',
				description:'',
				sample:{},
				params:[
					{
						parameter:'', 
						value:'',
						description:'Required in header',
						parameterType:'', 
						dataType:'String',
						displayName:'Api-Token',
						filedName:'token',
						isArray:true,
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'String',
						displayName:'block_id',
						filedName:'block_id',
						isArray:false,
					},
				],
				isQueryString:true,
				response:'',
				url:'/api/v1/get-block-cabins',
				type:'get'
			},
			{	
				mainTitle:'"',
				title:'',
				description:'',
				sample:{
						"block_id"		: "12188",
						"status"		: 4,
						"cabin_detail_ids": [35130,35138]
					},
				params:[
					{
						parameter:'', 
						value:'',
						description:'Required in header',
						parameterType:'', 
						dataType:'String',
						displayName:'Api-Token',
						filedName:'token'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Integer',
						displayName:'block_id',
						filedName:'block_id'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'status',
						displayName:'status',
						filedName:'status'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Array Integer',
						displayName:'cabin_detail_id',
						filedName:'cabin_detail_id',
						isArray:true
					},
				],
				response:'',
				isQueryString:true,
				url:'/api/v1/get-booked-cabin-detail',
				type:'get'
			},
			{	
				mainTitle:'"',
				title:'',
				description:'',
				sample:{
						"block_id":11784,
						"cabin_detail_ids":[34054,34055],
						"status":9
					},
				params:[
					{
						parameter:'', 
						value:'',
						description:'Required in header',
						parameterType:'', 
						dataType:'String',
						displayName:'Api-Token',
						filedName:'token'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Integer',
						displayName:'block_id',
						filedName:'block_id'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'status',
						displayName:'status',
						filedName:'status'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Array Integer',
						displayName:'cabin_detail_ids',
						filedName:'cabin_detail_ids',
						isArray:true
					},
				],
				response:'',
				url:'/api/v1/confirm-release-cabin',
				type:'post'
			},
			{	
				mainTitle:'"',
				title:'',
				description:'',
				sample:{
						"block_id":12030,
						"cabin_detail_ids" : [35130]
					},
				params:[
					{
						parameter:'', 
						value:'',
						description:'Required in header',
						parameterType:'', 
						dataType:'String',
						displayName:'Api-Token',
						filedName:'token'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Integer',
						displayName:'block_id',
						filedName:'block_id'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Integer',
						displayName:'cabin_detail_ids',
						filedName:'cabin_detail_ids',
						isArray:true,
					},
				],
				response:'',
				url:'/api/v1/cancel-cabins',
				type:'post'
			},
			{	
				mainTitle:'"',
				title:'',
				description:'',
				sample:{
						"cabin_detail_id" : 34052,
						"pax_details" : [
							{
								"first_name" : "roshan ",
								"last_name"  : "dhanke",
								"passport_no" : "",
								"date_of_birth" : "2017-01-11",
								"date_of_expire" : "2022-06-30",
								"date_of_issue" : "2012-05-1"
							},
							{
								"first_name" : "testing ",
								"last_name"  : "names",
								"passport_no" : "",
								"date_of_birth" : "1998-01-11",
								"date_of_expire" : "",
								"date_of_issue" : ""
							}
						]
					},
				params:[
					{
						parameter:'', 
						value:'',
						description:'Required in header',
						parameterType:'', 
						dataType:'String',
						displayName:'Api-Token',
						filedName:'token'
					},
					{
						parameter:'', 
						value:'',
						description:'Required',
						parameterType:'', 
						dataType:'Integer',
						displayName:'cabin_detail_id',
						filedName:'block_id'
					},
					{
						parameter:'', 
						value:[
								{
									"first_name" : "test ",
									"last_name"  : "test",
									"passport_no" : "",
									"date_of_birth" : "2017-01-11",
									"date_of_expire" : "2022-06-30",
									"date_of_issue" : "2012-05-1"
								},
								{
									"first_name" : "test ",
									"last_name"  : "test",
									"passport_no" : "",
									"date_of_birth" : "1998-01-11",
									"date_of_expire" : "",
									"date_of_issue" : ""
								}
							],
						description:'Required',
						parameterType:'', 
						dataType:'Integer',
						displayName:'pax_details',
						filedName:'pax_details',
						displayInput:true
					},
				],
				response:'',
				url:'/api/v1/update-booking-detail',
				type:'post'
			},
			{	
				mainTitle:'"',
				title:'',
				description:'',
				sample:{},
				params:[
					{
						parameter:'', 
						value:'',
						description:'Required in header',
						parameterType:'', 
						dataType:'String',
						displayName:'Api-Token',
						filedName:'token'
					},
					{
						parameter:'', 
						value:'11784',
						description:'Required',
						parameterType:'', 
						dataType:'Integer',
						displayName:'block_id',
						filedName:'block_id'
					},
					{
						parameter:'', 
						value:'34052',
						description:'Required',
						parameterType:'', 
						dataType:'Integer',
						displayName:'cabin_detail_id',
						filedName:'cabin_detail_id'
					},
				],
				isQueryString:true,
				response:'',
				url:'/api/v1/get-cancellation-amount',
				type:'get'
			},
			{	
				mainTitle:'"',
				title:'',
				description:'',
				sample:{},
				params:[
					{
						parameter:'', 
						value:'',
						description:'Required in header',
						parameterType:'', 
						dataType:'String',
						displayName:'Api-Token',
						filedName:'token'
					},
					{
						parameter:'', 
						value:'11784',
						description:'Required',
						parameterType:'', 
						dataType:'Integer',
						displayName:'block_id',
						filedName:'block_id'
					},
					{
						parameter:'', 
						value:'34052',
						description:'Required',
						parameterType:'', 
						dataType:'Integer',
						displayName:'cabin_detail_id',
						filedName:'cabin_detail_id'
					},
				],
				isQueryString:true,
				response:'',
				url:'/api/v1/get-cancellation-amount',
				type:'get'
			}

		]
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	inputChildHandler = (child,index, e) => {
		const field = e.target.name;
		const value = e.target.value;
		this.state.data[child].params[index].value = value
		let data = this.state.data;
		data[child].params[index].value = value
		data[child].response = value
		const token = data[child].params[0].value
		
		this.setState({
			data,
			token
		})
	
	}
	componentDidMount(val){
		// console.log(val)
		if(typeof val === "undefined"){
			return false;
		}
		//start loader
		this.setState({
			loading:true
		})
		const config = {
			headers: {
				"Access-Control-Allow-Origin": "*",
				'Content-Type': 'application/json',
				'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
				'token':this.state.token
			}
		};
		const childData = this.state.data[val];
		const array = this.state.data[val].params;
		const object = {};
		
		//create requet object
		_.forEach(array, function(value) {
			
			if(value.isArray){
				//conver value to array
				console.log(value.value.split(",").map(Number));
				object[value.filedName] = value.value.split(",").map(Number)
			}else{
				object[value.filedName] = value.value
			}
			
		});
		let vm = this;
		let queryString = '';
		
		if(childData.isQueryString){
			queryString = '?'+ Object.keys(object).map(key => key + '=' + object[key]).join('&');
		}
		
		axios({
			method: childData.type,
			url: 'http://api.deckpro.in'+childData.url+queryString,
			data: object,
			// headers:{
			// 	// 'Access-Control-Allow-Origin': '*',
        	// 	// 'Content-Type': 'application/json1',
			// 	// 'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH',
			// 	// 'token':"dsadas"
			// 	token:'testst'
			// }
			headers: { 
				'token': this.state.token,
				'Content-Type': 'application/json'
			 },
			auth: {
				'username': 'api',
				'password': 'T8u6v4Z*I#s'
			}
			
		  })
		  .then(function (response) {
				const test = JSON.stringify(response.data,null,4);
				let data = vm.state.data;
				data[val].response = test
				// console.log(data)
				vm.setState({
					loading:false, //stop loader
					data,
				})
			}).catch(error=>{
				let data = vm.state.data;
				data[val].response =JSON.stringify(error.response.data,null,4) 
				vm.setState({
					loading:false, //stop loader,
					data
				})
				console.log(error.response.data)
			});
		// $2y$10$nOpoTUFpjeI5Hhdm3Ylt8ex/v3FppIlYvo9hAlPOw0STE62vzLgNm live
		// $2y$10$PL.S3MVVtdnbSGWMio494eaAu0gQ9C2fKuKZtDJFlbatUTgIOBpxG	 testing
	}
	
	handleChange(event) {
		this.setState({value: event.target.value});
	}
	

	handleSubmit = (val,event) => {
		event.preventDefault();
		//create objects for request
		const paramArr = this.state.data[val].params;
		const token = this.state.data[val].params[0].value
		this.componentDidMount(val);
		// this.setState({
		// 	token,
		// })
	}
	
	render() {
	  return (
		<div>
			<ClipLoader css={override} sizeUnit={"px"} size={50} color={'#123abc'} loading={this.state.loading}
				/>
			<Accordion atomic={true}>
			{this.state.data.map((child, index) => (
					<AccordionItem title={child.type +" :- "+ child.url} key={index}>
					<div style={{textAlign:'left',background:'white', padding:'10px'}}>
					<p>	
						<b>Implementation Notes</b>
						
						<br/>
							<span>{child.description}</span>
					</p>
					<p>Content-Type: application/json</p>
						<br/>
						<b>Sample</b>
						<div className="sample-box">
							<p>
								<pre>
									{JSON.stringify(child.sample,null,4)}
								</pre>
							</p>
						</div>
					<div>
						<form onSubmit={this.handleSubmit.bind(this, index)}>
							<h5>Parameters</h5>
							<table className='table'>
								<thead>
								<tr>
									<th>Parameter</th>
									<th>Value</th>
									<th>Description</th>
									<th>Parameter Type</th>
									<th>Data Type</th>
								</tr>
									</thead>
									<tbody>
								{child.params.map((params, paramIndex)=>(

									<tr>
										<td>{params.displayName}</td>
										<td>
										
											{!params.displayInput ? (
												<input type="text"
												className='form-control'
												placeholder={params.displayName} 
											   //  value={params.value} 
												name={params.filedName} 
												onChange={this.inputChildHandler.bind(this, index, paramIndex)} 
												autoComplete='off'
												/>
											) : (
												<div>
													<pre>
														{JSON.stringify(params.value,null,4)}
													</pre>
												</div>
											)}
											
										</td>
										<td>{params.description}</td>
										<td>{params.parameterType}</td>
										<td>{params.dataType}</td>
									</tr>
								))}
								</tbody>
							</table>
							<input className='btn btn-success' type="submit" value="Submit" />
						</form>
						<div>
							<br/>
							<h5>Respose</h5>
							<div className='sample-box'>
								<pre>
									{child.response}
								</pre>
							</div>
						</div>
					</div>
				</div>
				</AccordionItem>
				))}
				
			</Accordion>
		</div>
	  );
	}
	
  }
export default Accordions;